<template>
  <v-app>
    <v-app-bar
      app
      color="primary"
      dark
      class="d-flex justify-center align-center"
      dense
    >
      <div class="d-flex align-center">
        <v-img
          alt="Vuetify Name"
          class="shrink mt-1 hidden-sm-and-down"
          contain
          :src="require('@/assets/FocaliaBlanc.png')"
          min-width="80"
          width="80"
        />
      </div>
    </v-app-bar>

    <v-main>
      <v-container class="fill-height justify-center align-center">
        <router-view></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",

  data: () => ({
    //
  })
};
</script>
