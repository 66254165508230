<template>
  <div class='align-self-start fill-height align-center d-flex' style='max-width: 100%'>
    <v-row class='text-center' v-if='loading && this.$route.query.k' >
      <v-col cols='12'>
        <v-progress-circular
          :size='70'
          :width='7'
          color='primary'
          indeterminate
        ></v-progress-circular>
      </v-col>
    </v-row>

    <div v-else-if='order !== null' class='align-self-start' style='max-width: 100%'>
      <v-card
        width='400px'
        max-width='100%'
        class='align-self-start'
        tile
      >
        <v-card-title>Votre ephoto</v-card-title>
        <v-divider></v-divider>
        <v-card-text class='pb-0'>
          <div><b>Créée le :</b> {{ formatDate(order.created_at) }}</div>
          <div>
            <b>Valide pour une utilisation jusqu'au :</b>
            {{ formatDate(order.expiration_date) }}
          </div>
          <div>
            <b>Status : </b>
            <span :class='status.class' v-text='status.text'></span>
          </div>
          <div v-if='order.invalid_motif'>
            <b>Motif de refus : </b>
            {{ order.invalid_motif }}
          </div>
          <div class='mt-5'>
            <v-text-field
              label='Votre code ephoto'
              outlined
              :value='order.code_ephoto'
              readonly
            >
              <template #append>
                <v-tooltip bottom>
                  <template v-slot:activator='{ on }'>
                    <v-btn icon style='top:-8px' v-on='on' @click='copyEphoto'>
                      <v-icon>mdi-content-copy</v-icon>
                    </v-btn>
                  </template>
                  {{ tooltipText }}
                </v-tooltip>
              </template>
            </v-text-field>
          </div>
        </v-card-text>
      </v-card>
      <v-card max-width='100%' width='400px' class='mt-4' tile>
        <v-card-title>
          Instructions
        </v-card-title>
        <v-divider></v-divider>
      </v-card>
      <v-expansion-panels accordion tile style='width:400px;max-width: 100%'>
        <v-expansion-panel
        >
          <v-expansion-panel-header>
            Permis de conduire
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <ol>
              <li>Rendez-vous sur <a href='https://permisdeconduire.ants.gouv.fr/'>https://permisdeconduire.ants.gouv.fr/</a>
              </li>
              <li>Connectez-vous à votre compte ou créez-en un</li>
              <li>Remplissez le formulaire de prédemande en ligne</li>
              <li>Lors de l'étape 4, copiez-collez le code ephoto ci-dessus dans le champ "Code photo signature
                numérique"
              </li>
              <li>Terminez de remplir le formulaire puis attendez la confirmation par email</li>
              <li>Conservez vos photos lors de votre rendez-vous en préfecture</li>
            </ol>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel
        >
          <v-expansion-panel-header>
            Titre de séjour
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <p>Suivez les instructions officielles sur <a
              href='https://france-visas.gouv.fr/web/france-visas/accueil'>https://france-visas.gouv.fr/web/france-visas/accueil</a>
            </p>
            <p>
              Retrouvez également les autres pièces justificatives à fournir ici : <a
              href='https://administration-etrangers-en-france.interieur.gouv.fr/particuliers/assets/docs/justificatifs-etudiant.pdf'>
              https://administration-etrangers-en-france.interieur.gouv.fr
            </a>
            </p>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>

    <v-card v-else width='400px' max-width='100%' class='align-self-start align-self-md-center'>
      <v-card-title>Accès à votre ephoto</v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <div class='mb-4'>
          Saisissez les 4 derniers caractères de votre code ephoto :
        </div>
        <v-form class='d-flex justify-center' @submit.prevent.stop='fetchData'>
          <v-text-field
            label='4 Caractères'
            v-model='lastChars'
            outlined
            maxlength='4'
            style='width: 140px; font-size: 1.5rem'
            class='d-inline-flex flex-grow-0'
            autofocus
            :error-messages='error'
          ></v-text-field>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn
          color='success'
          class='ml-auto'
          :disabled='lastChars.length !== 4'
          @click='fetchData'
          type='submit'
          :loading='loading'
        >Valider
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'Customer',

  data: () => ({
    loading: false,
    askLastChars: true,
    lastChars: '',
    error: null,
    order: null,
    tooltipText: 'Copier',
  }),
  computed: {
    status () {
      if (this.order === null) {
        return {}
      }
      switch (this.order.status) {
        case 'CREEE':
          return {
            text: 'Disponible',
            class: 'success--text',
          }
        case 'RESERVEE':
          return {
            text: 'En cours d\'utilisation',
            class: 'warning--text',
          }
        case 'PERIMEE':
          return {
            text: 'Périmée',
            class: 'error--text',
          }
        default:
          return {
            text: 'Consomée',
            class: 'error--text',
          }
      }
    },
  },
  methods: {
    fetchData (e) {
      if (e && this.lastChars.length !== 4) {
        return (this.error = 'Ce champ doit comporter 4 caractères')
      }
      this.loading = true
      this.error = null
      axios
        .get(
          process.env.VUE_APP_API_BASE_URL +
          `/api/customer/${this.$route.params.code}`,
          {
            params: {
              key: this.$route.query.k,
              'last-chars': this.lastChars.length ? this.lastChars : null,
            },
          },
        )
        .then(response => {
          if (!response.data.success) {
            this.error = response.data.message
          } else {
            this.order = response.data.order
          }
        })
        .finally(() => (this.loading = false))
    },
    formatDate (date, hour = false) {
      if (typeof date === 'string') {
        date = new Date(date)
      }
      return new Intl.DateTimeFormat('fr', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: hour ? 'numeric' : undefined,
        minute: hour ? 'numeric' : undefined,
      }).format(date)
    },
    copyEphoto () {
      this.$copyText(this.order.code_ephoto).then(
        () => {
          this.tooltipText = 'Copié !'
          setTimeout(() => (this.tooltipText = 'Copier'), 3000)
        },
        () => {
          this.tooltipText = 'Impossible de copier automatiquement'
          setTimeout(() => (this.tooltipText = 'Copier'), 3000)
        },
      )
    },
  },
  created () {
    if (this.$route.query.k) {
      this.fetchData()
    }
  },
}
</script>
