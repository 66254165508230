import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
// import fr from 'vuetify/src/locale/fr'

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: "#8a2c48",
        secondary: "#2c4b8a",
        accent: "#c8c8c8",
        error: "#DD4040",
        warning: "#EA8E48",
        info: "#528BC4",
        success: "#46bd87"
      }
    }
  },
  lang: {
    // locales: { fr },
    current: "fr"
  }
});
