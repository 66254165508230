<template>
  <div class="d-flex text-h5">
    <div>Allez sur l'URL indiquée sur votre planche photo ou scannez le QR Code</div>
  </div>
</template>

<script>

export default {
  name: "Home",
};
</script>
